.RealTimeCard {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    background: #FFFFFF;
    padding: 25px;
}

.RealTimeCard img {
    height: 40px;
}

.RealTimeCard .DetailsRow {
    padding: 0px 5px;
    box-sizing: border-box;
}

.RealTimeCard .DetailsRow:nth-child(even) {
background: #F5F5F5;
}
.ProsDetails {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    padding: 40px;
}

.ProsDetails .HeadLine {
    grid-column: 1 / 13;
    box-sizing: border-box;
}

.ProsDetails .ProsDetailsHeadlineCard {
    grid-column: 1 / 13;
    box-sizing: border-box;
}

.ProsDetails .ProsDetailsKarma {
    grid-column: 1 / 7;
    box-sizing: border-box;
}

.ProsDetails .SplashLoadCard {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.ProsDetails .LabelCard {
    font-size: 12px;
    height: fit-content;
}
.Podium .Podium1 {
    background: rgba(13,110,253,0.25);
    border: 2px solid rgb(13,110,253);
    width: 150px;
    height: 75px;
}

.Podium .Podium2 {
    background: rgba(13,110,253,0.25);
    border: 2px solid rgb(13,110,253);
    width: 150px;
    height: 50px;
    border-right: unset;
}

.Podium .Podium3 {
    background: rgba(13,110,253,0.25);
    border: 2px solid rgb(13,110,253);
    width: 150px;
    height: 25px;
    border-left: unset;
}

.Podium {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    background: #FFFFFF;
    padding: 25px;
}

.Podium .PodiumAvatar {
    width: 75px;
}
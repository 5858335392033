.ProsDetailsKarma {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    background: #FFFFFF;
    padding: 25px;
}

.FBPositive {
    height: 35px;
    border-left: 3px solid #27ae60;
    padding-left: 5px;
    background: #27ae5f22;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.FBNegative {
    height: 35px;
    border-right: 3px solid #e74c3c;
    padding-right: 5px;
    background: #e74c3c22;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.WebsiteConsolidation {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    background: #FFFFFF;
    padding: 25px;
}

.WebsiteConsolidation thead th {
    padding: 5px;
}

.WebsiteConsolidationList {
    max-height: 500px;
    overflow-y: scroll;
    position: relative;
}

.WebsiteConsolidation .GoogleButtonImg {
    margin-bottom:3px;
    margin-right:5px;
}

.WebsiteConsolidation table {
    width: 100%;
}

.WebsiteConsolidation .GoogleButton {
    height: 30px;
}

.WebsiteConsolidation .GoogleButton > div {
    padding: 2px 5px !important;
}

.WebsiteConsolidation tr:nth-child(even) {
    background-color: #f5f5f5;
}

.ConfirmCard {
    border: 2px solid #222222;
    padding: 25px;
    border-radius: 3px;
    background: #f5f5f5;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
}
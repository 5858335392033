.PayPlan {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    background: #FFFFFF;
    padding: 25px;
}

.PayPlanContainer {
    max-height: 500px;
    overflow-y: scroll;
}

.PayPlan table {
    width: 100%;
}

.PayPlan .BonusMonth {
    font-size: 5rem;
}

.PayPlan .TitleBonusMonth {
    text-align: center;
}

.PayPlan tr:nth-child(even) {
    background-color: #ffcbcb;
}

.PayPlan tr:nth-child(even) {
    background-color: #ffcbcb;
}

.PayPlan .PayPlanGraphHistory {
    height: 300px;
    max-height: 300px;
}

.PayPlan .filter-button:hover {
    cursor: pointer;
    color: #ffcbcb;
}

.PayPlan .filter-active {
    color: #6c5ce7;
}

.PayPlan .filter-button {
    font-size: 25px;
}

.PayPlan .PB15 {
    padding-bottom: 15px;
}
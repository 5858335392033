.HeadLine .ActionButton {
    width: fit-content;
    margin-left: auto;
    margin-bottom: 10px;
    text-decoration: none;
}

.HeadLine .ActionButton:hover {
    opacity: 0.8;
}

.HeadLine .AvatarIco {
    height: 75px;
}

.HeadLine .Button.AvatarAction {
    width: 100%;
    height: 25px;
    padding: 0px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    margin-bottom: 10px;
    text-decoration: none;
    background: #ec2121;
    border: none;
    color: #FFFFFF;
    border-radius: 3px;
    cursor: pointer;
}

.HeadLine .Button.AvatarAction:hover {
    opacity: 0.8;
}
.LineCard .Headline {
    color: #FFFFFF;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    font-size: 18px;
    text-align: center;
    margin-top: 0px;
}

.LineCard {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    background: #FFFFFF;
    border-radius: 12px;
    min-height: 300px;
}

.LineCard .ContainerLineRowTable {
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom: 10px;
}
.WebsiteConsolidationRow td {
    text-align: center;
}

.WebsiteConsolidationRow td:first-child {
    text-align: left;
}

.WebsiteConsolidationRow .Disabled {
    cursor: not-allowed;
}

.WebsiteConsolidationRow .Disabled:hover {
    opacity: 1;
}

.StepTitle {
    color: #f41127;
    font-size: 48px;
}

.InputFeedback {
    height: 40px;
    border-radius: 3px;
    max-width: 400px;
}

.InputFeedback.LightBorder {
    border: 1px solid #2a2a2a;
}

.TextFeedback {
    border-radius: 3px;
    max-width: 100%;
    height: 100px;
}
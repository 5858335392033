.SalesUser {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    padding: 40px;
}

.SalesUser .HeadLine {
    grid-column: 1 / 13;
    box-sizing: border-box;
}

.SalesUser .SalesGoalCard {
    grid-column: 1 / 13;
    box-sizing: border-box; 
}

.SalesUser .SalesWarmlistCard {
    grid-column: 1 / 13;
    box-sizing: border-box; 
}

.SalesUser .SalesLastProsCard {
    grid-column: 1 / 13;
    box-sizing: border-box; 
}

.SalesUser .SalesLastSubCard {
    grid-column: 1 / 13;
    box-sizing: border-box; 
}

.SalesUser .SalesProspectedGraphCard {
    grid-column: 1 / 7;
    box-sizing: border-box; 
}

.SalesUser .SalesSubscribedGraphCard {
    grid-column: 7 / 13;
    box-sizing: border-box; 
}

.SalesUser .SalesLastConfirmedWebsitesCard {
    grid-column: 1 / 13;
    box-sizing: border-box; 
}

.SalesUser .PayPlanCard {
    grid-column: 1 / 13;
    box-sizing: border-box; 
}

.SalesUser .SplashLoadCard {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}
.SalesStatistic {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    background: #FFFFFF;
    padding: 25px;
}

.SalesStatistic table {
    width: 100%;
}

.SalesStatistic th {
    padding: 5px;
}

.SalesStatistic tr:nth-child(even){
    background-color: #f5f5f5;
}

.SalesStatistic tr.BRed {
    background-color: #f41127;
}

.SalesStatistic tr {
    transition: all 0.3s;
}

.SalesStatistic tr:hover {
    background-color: #ee8f98;
}

.SalesStatistic thead tr:hover {
    background: unset;
}

.SalesStatistic .FilterOrderButton {
    font-size: 18px;
}

.SalesStatistic .FilterOrderButton:hover {
    cursor: pointer;
    opacity: 1;
}

.FilterInactive {
    opacity: 0.7;
}

.SalesStatistic th {
    font-size: 14px;
}
.CaSales {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    background: #FFFFFF;
    padding: 25px;
}

.CaSalesGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    padding: 40px;
}

.CaSales .DayPickerObject {
    width: fit-content;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 40%);
    margin-left: 0px;
    margin-right: 0px;
}

.CaSales .CaSalesRowCard {
    box-sizing: border-box;
}
.BonusOverview {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    background: #FFFFFF;
    padding: 25px;
}

.BonusOverviewContainer tr:nth-child(even){
    background-color: #f5f5f5;
}

.BonusOverviewContainer tr:hover{
    background-color: #ee8f98;
}

.BonusOverviewSelector {
    padding: 5px;
    border: 1px solid #222222;
    border-radius: 3px;
}
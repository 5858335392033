.LastWebsites {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    background: #FFFFFF;
    padding: 25px;
}

.LastWebsitesContainer tbody tr:nth-child(even) {
    background: #F5F5F5;
}

.LastWebsitesContainer {
    max-height: 500px;
    overflow-y: scroll;
}

.LastWebsitesContainer .SearchField {
    height: 30px;
}
.LineCardRow {
    border-radius: 4px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    border: 1px solid #dbdbdb;
    background: #FFFFFF;
    padding: 5px;
    margin: 5px;
}

.LineCardRow h2 {
    font-size: 16px;
}

.LineCardRow p {
    font-size: 14px;
}

.LineCardRow p span {
    font-size: 12px;
}

.LineCardRow .LabelCard {
    font-size: 8px;
    padding: 0px 5px;
    margin: 0px;
}

.LineCardRow .LabelCard.MR10 {
    margin-right: 10px;
}

.LineCardRow .LabelCard {
    font-size: 14px;
    padding: 2px 10px;
}
.EasyExport {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    background: #FFFFFF;
    padding: 25px;
}

.EasyExport .DateCol {
    min-width: 235px;
}

.EasyExport .OptionsCol {
    min-width: 315px;
}

.EasyExport .OptionsCol .RangeInput{
filter: hue-rotate(140deg);
}

.EasyExport .OptionsCol select {
    border-radius: 3px;
    border-color: #222222;
    height: 25px;
}

.EasyExport .MiddleArrow {
    font-size: 4rem;
}

.EasyExport .DownloadButton {
    height: 35px;
}

.EasyExport .NoDownload {
    opacity: 0.5;
    cursor: not-allowed;
}

.EasyExport .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
}
  
/* Hide default HTML checkbox */
.EasyExport .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
  
/* The slider */
.EasyExport .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f41127;
    -webkit-transition: .4s;
    transition: .4s;
}
  
.EasyExport .slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
  
.EasyExport input:checked + .slider {
    background-color: #f41127;
}
  
.EasyExport input:focus + .slider {
    box-shadow: 0 0 1px #f41127;
}
  
.EasyExport input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}
  
/* Rounded sliders */
.EasyExport .slider.round {
    border-radius: 17px;
}
  
.EasyExport .slider.round:before {
    border-radius: 50%;
}

.EasyExport .Unselected {
    opacity: 0.6;
}
.Home {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 10px;
    grid-row-gap: 20px;
    padding: 40px;
}

.Home .HeadLine {
    grid-column: 1 / 13;
    box-sizing: border-box;
}

.Home .Card1 {
    grid-column: 1 / 4;
    box-sizing: border-box;
}

.Home .Card2 {
    grid-column: 4 / 7;
    box-sizing: border-box;
}

.Home .Card3 {
    grid-column: 7 / 10;
    box-sizing: border-box;
}

.Home .Card4 {
    grid-column: 10 / 13;
    box-sizing: border-box;
}

.Home .PodiumCard {
    grid-column: 1 / 13;
    box-sizing: border-box;
}

.Home .RealTimeSubscriptionCard {
    grid-column: 7 / 13;
    box-sizing: border-box;
}

.Home .RealTimeProspectionCard {
    grid-column: 1 / 7;
    box-sizing: border-box;
}

.Home .LastWebsitesCard {
    grid-column: 1 / 13;
    box-sizing: border-box;
}

.SalesStatisticCard {
    grid-column: 1 / 13;
    box-sizing: border-box;
}

.WebsiteConsolidationCard {
    grid-column: 1 / 13;
    box-sizing: border-box;  
}

.WarmListCard {
    grid-column: 1 / 13;
    box-sizing: border-box;    
}

.EasyExportCard {
    grid-column: 1 / 13;
    box-sizing: border-box;    
}

.LemlistImportCard {
    grid-column: 1 / 13;
    box-sizing: border-box;   
}

.AvatarCard {
    grid-column: 1 / 13;
    box-sizing: border-box;
}

.GraphSubscribedCard {
    grid-column: 7 / 13;
    box-sizing: border-box;
}

.GraphProspectedCard {
    grid-column: 1 / 7;
    box-sizing: border-box;
}

.Home .LineCardFirstContact {
    grid-column: 1 / 5;
    box-sizing: border-box;
}

.Home .LineCardDataConsolidation {
    grid-column: 9 / 13;
    box-sizing: border-box;
}

.Home .LineCardReminder {
    grid-column: 5 / 9;
    box-sizing: border-box;
}

.Home .LineCardObjective {
    grid-column: 9 / 13;
    box-sizing: border-box;
}

.Home .LineCardLastSubscription {
    grid-column: 5 / 9;
    box-sizing: border-box;   
}

.Home .LineCardWarmlist {
    grid-column: 1 / 5;
    box-sizing: border-box;   
}




.Home .DCard1 {
    grid-column: 9 / 11;
    box-sizing: border-box;
}

.Home .DCard2 {
    grid-column: 11 / 13;
    box-sizing: border-box;
}

.Home .DCard3 {
    grid-column: 9 / 13;
    box-sizing: border-box;
}

.Home .DCard4 {
    grid-column: 11 / 13;
    box-sizing: border-box;
}

.Home .SiteState {
    grid-column: 1 / 9;
    box-sizing: border-box;
}

.Home .ChartCard {
    grid-column: 9 / 13;
    box-sizing: border-box;
}

.Home .SplashLoadCard {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.DCard1:hover, .DCard2:hover, .DCard3:hover, .DCard4:hover {
    opacity: 0.8;
}
.LemlistImportCard {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    background: #FFFFFF;
    padding: 25px;
}

.LemlistImportContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.LemlistImportContainer select {
    height: 40px;
}

.LemlistImportContainer textarea {
    height: 500px;
}

.LemlistImportContainer .ImportValidation {
    height: 40px;
}

.FirstCol { grid-area: 1 / 1 / 2 / 3; }
.TextAreaImport { grid-area: 2 / 1 / 3 / 3; }
.SecondCol { grid-area: 1 / 3 / 2 / 4; }
.ImportValidation { grid-area: 2 / 3 / 3 / 4; }
.LastConfirmedWebsites {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    background: #FFFFFF;
    padding: 25px;
}

.LastConfirmedWebsitesGrid {
    max-height: 400px;
    overflow-y: scroll;
}
.ImportAdminProspection {
    border-radius: 12px;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);
    background: #f5f5f5;
    padding: 25px;
}

.ImportAdminProspection .ValidationButton {
    height: 35px;
}

.ImportAdminProspection .ImportTextArea {
    height: 150px;
}